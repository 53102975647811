import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import "../CSS/Benefits.css";
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../Component/Footer';

const Benefits = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>

      {/* /////////////////////// SEO ////////////////////////// */}

      <Helmet>
        <meta name='description' content='Mahip Agarwal is one of the best vastu expert in Mumbai. With more the 10 years as vastu consultant he helped lot of his clients achieve success. Call Now!' />
        <meta name="title" content="Best Vastu Expert in Mumbai | By Mahip Agarwal  Consult Now!" />
        <meta name="keywords" content="best vastu consultant,vastu consultant,vastu shastra consultant,vastu expert in mumbai,vastu consultant in mumbai,best vastu consultant in mumbai,best vastu expert in Mumbai" />
        {/* <!-- Open Graph --> */}
        <meta property="og:url" content="http://divineastrovastu.in/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Divine Astro Vastu" />

        <link rel="canonical" href="http://divineastrovastu.in/ " />
      </Helmet>

      {/* /////////////////////// SEO ////////////////////////// */}


      <section className='Benefits-Secito-1'>
        <div className='container'>
          <div className='Benefits-Section'>
            <div className='BS-Head'>
              <h1>
                BENEFITS OF VASTU
              </h1>
            </div>
            <div className='Benefits-Accordion'>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>1.	Helps to attract Money and Opportunities</Accordion.Header>
                  <Accordion.Body>
                    When proper Vastu is applied to any house/ office, it helps to attract money and new growth opportunities. The members can experience regular flow of cash and  an exponential growth in their cashflows. With the help of Vastu remedies, wastage of money can be avoided and blocked payments can be recovered. The North zone of the house must be balanced to avoid liquidity issues. If red, yellow or orange colour is used in the North zone, then the residents will not get new opportunities. The Vastu remedy to attract money and growth is to place Kuber and a lush green painting in black frame in the North zone.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>2.	Helps in Improving Family Harmony and Bonding</Accordion.Header>
                  <Accordion.Body>
                    The South West Zone of the house must be balanced for improving family relations and bonding between the members. If the South west zone of the house is disturbed or there are plants or green/ blue colour is used in that area, the family harmony is disturbed. To improve the relationships between the members South west zone needs to be balanced and green colour or green plants have to be removed from that zone. The Vastu remedy for improving family harmony is to place family and ancestors photograph in golden frame in the South west zone.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>3.	Helps to avoid delay in Marriage and Childbirth</Accordion.Header>
                  <Accordion.Body>
                    The South West zone of a house must be balanced to prevent any delays in marriage or child birth. If the South West zone of the house is disturbed i.e. either it is extended or cut, it creates issues in child birth. To prevent delays in marriage of the members of the house, the East zone of the house must be balanced which improves social connectivity.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>4.	Helps to attain Wisdom and Clarity of Vision</Accordion.Header>
                  <Accordion.Body>
                    One of the benefits of applying Vastu to your home / office is that people get mental clarity and wisdom to see their lives with a broader perspective. Vastu remedies helps the people to achieve mental clarity in all aspects of their lives and  to connect to their own higher self. Due to the mental clarity and positive energy for growth, the people are able to move ahead in their lives and experience growth and prosperity.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>5.	Helps in improving health and immunity</Accordion.Header>
                  <Accordion.Body>
                    The health of a person is governed by the place they live in. If proper Vastu is done by balancing the different elements and governing the different zones, diseases can be cured from home itself. Any imbalance in the zones related to health can cause different ailments like any imbalance in the NNE zone combined with imbalances NE zone can cause severe ailments like paralysis, cancer leukaemia and so on. If any part of the body is afflicted or diseased, it is clear that the zone which represents that body part is imbalanced or there is some anti element or anti - activity in that zone. Using this system of Vastu, we can identify the zone of the house which needs to be treated and find the best solution to overcome problems associated with that imbalanced zone.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>6.	Helps to attract new Opportunities in career</Accordion.Header>
                  <Accordion.Body>
                    If money plant is placed in the zone of money and opportunities, it helps to attract new growth opportunities to enhance the career of an individual. There is zone in Vastu which can help to create new opportunities and  Vastu remedies can help to provide solutions according to the specific requirements of the individual. Whether the person wants a promotion, wants to change the present job and want better job offers or want to shift from one industry to another, any such desired results can be achieved by applying proper Vastu Shastra.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>7.	Helps in the growth in business</Accordion.Header>
                  <Accordion.Body>
                    By balancing various zones of the property and applying specific remedies, Vastu Shastra can help a business to attract new customers, retain old clients, create new growth opportunities and in the overall growth of their business. It can help a business to utilise their maximum production capacity, to curtail breakdown of machines, to increase their brand strength and get cooperative staff.The North Zone of the property must be balanced for proper growth in the business. The Vastu remedy to attract growth in business is to place a lush green painting or use natural plants or money plant in the North zone to attract growth and new opportunities in the business
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>8.	Helps to rediscover true joy and happiness</Accordion.Header>
                  <Accordion.Body>
                    The ENE zone of any property is the zone of recreation and jest in life. The energy of this zone helps to refresh the mind and body and adds long lasting fun in the lives of the residents of the property. The positive energy of the ENE zone can eliminate negativity from the thoughts of the members and fills their mind with positivity and vitality. The energy of ENE zone infuses the members with strength and power which helps them to put their ideas into practice
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>9.	Helps to get financial support and quick sanction of loans</Accordion.Header>
                  <Accordion.Body>
                    If the zones of a property are balanced by applying proper Vastu Shastra and specific remedies are applied, it can help to attract the right investor or financial support from banks or market. After applying Vastu remedies, one can get secure and fruitful financial support for their business and also settle their financial obligations. The Vastu remedies can help to overcome bad impacts of wrong location of entrances which creates hindrances in getting financial support.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>10.	Helps to overcome unwanted fears</Accordion.Header>
                  <Accordion.Body>
                    According to Vastu shastra, the energy of WNW zone helps in natural detoxification and release of emotional fatigue. IF the various zones of the property are balanced and if there is fire in the WNW zone (kitchen in the WNW zone), then the residents will never brood over their past because their emotional toxification is being burnt out.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>11.	Helps to attain stability and peace of mind</Accordion.Header>
                  <Accordion.Body>
                    In Vastu Shastra, tortoise is used as a powerful Vastu remedy to attain various benefits like sense of peace and stability in life. If the tortoise is placed in the West zone, it helps in success and prosperity. If the tortoise is placed in the centre of the building, it helps to bring stability, patience and perseverance in the life of the residents. The tortoise when  placed in the right direction helps to save the residents from unnecessary activities and futile attempts and protects them.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>12.	Prevents wastage of efforts and provides fruitful results for the inputs</Accordion.Header>
                  <Accordion.Body>
                    The South Zone of the property must be balanced to give effective results of all the inputs and efforts of an individual. After balancing the property, if we palce a red elephant on the floor, its helps to prevent wastage of efforts and achieve fruitful results.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>13.	Creates new opportunities and helps in promotion</Accordion.Header>
                  <Accordion.Body>
                    Vastu Shastra can help to attract new opportunities and help in promotion of the residents by first balancing the different zones of the property and then applying specific Vastu remedies. If plants with broad green leaves are placed in the North zone, one can immediately receive new opportunities in their career. If a lush green landscape is hung on the North wall, it helps to get recognition, fame and promotion.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>14.	Helps to control unnecessary expenditure and increase savings</Accordion.Header>
                  <Accordion.Body>
                    With the help of Vastu Shastra, unnecessary expenditures can be avoided and the savings of the residents can be increased. If the residents of any property believe that their skills are being wasted or they are not able to put their skills to proper use, then with the help of specific Vastu remedies their skills and talents can be used beneficially.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>15.	Helps to build powerful connections</Accordion.Header>
                  <Accordion.Body>
                    If various zones of the property are balanced and specific Vastu remedies are applies, it can help the residents to build powerful connections. If a pair of rabbits (Black and white and made of brass) is placed on the floor in the ESE zone of the property, it will help to turn the relationships beneficial for the individual and they can get positive returns. This remedy also helps to reduce unwanted thoughts and overthinking and helps the person to be at peace with oneself.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Benefits

import './App.css';
import NavBar from './Component/NavBar';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Pages/Home';
import Benefits from "./Pages/Benefits";
import Services from "./Pages/Services";
import Testimonial from "./Pages/Testimonial";
import FAQS from "./Pages/FAQS";
import Myths from "./Pages/Myths";
import Contact from "./Pages/Contact";
import NotFound from './Pages/NotFound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/best-vastu-consultant' element={<Benefits />} />
          <Route path='/vastu-consultant' element={<Services />} />
          <Route path='/vastu-shastra-consultant' element={<Testimonial />} />
          <Route path='/vastu-expert-in-mumbai' element={<FAQS />} />
          <Route path='/vastu-consultant-in-mumbai' element={<Myths />} />
          <Route path='/best-vastu-consultant-in-mumbai' element={<Contact />} />
          <Route path=' *' element={<NotFound />} />
        </Routes>


      </BrowserRouter>
    </div>
  );
}

export default App;

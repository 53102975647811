import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import "../CSS/Faqs.css";
import FAQImage from "../Images/FAQ Image.png";
import Footer from "../Component/Footer";
import { BsPatchQuestion } from 'react-icons/bs';

const FAQS = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>

      {/* /////////////////////// SEO ////////////////////////// */}

      <Helmet>
        <meta name='description' content='Mahip Agarwal is one of the best vastu expert in Mumbai. With more the 10 years as vastu consultant he helped lot of his clients achieve success. Call Now!' />
        <meta name="title" content="Best Vastu Expert in Mumbai | By Mahip Agarwal  Consult Now!" />
        <meta name="keywords" content="best vastu consultant,vastu consultant,vastu shastra consultant,vastu expert in mumbai,vastu consultant in mumbai,best vastu consultant in mumbai,best vastu expert in Mumbai" />
        {/* <!-- Open Graph --> */}
        <meta property="og:url" content="http://divineastrovastu.in/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Divine Astro Vastu" />

        <link rel="canonical" href="http://divineastrovastu.in/ " />
      </Helmet>

      {/* /////////////////////// SEO ////////////////////////// */}


      {/* ///////////////////////////// Section 2 ///////////////////////////// */}

      <section className='FAQ-Section-2'>
        <div className='FAQ-Text'>
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className='container'>

          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Is Vastu applicable on rented house.</h3>
              <p>
                Yes, Vastu is applicable to rented houses also. When we start living in any house, our subconscious mind gets connected to the energy of that place. The colours used in that house, various objects placed and the different activities done in various zones of the house affects the members of the house.
              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Does Vastu involve demolition and drastic changes</h3>
              <p>
                No, in most of the cases demolition is not required while applying Vastu remedies. Only if there is a toilet in the North East Zone of the house/ office, then demolition is required otherwise in most of the other cases Vastu dosh can be solved by simple remedies like colour techniques, …
              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Is vast applicable in Flat system.</h3>
              <p>
                Yes Vastu is applicable in flat system also. The colours used in the net usable space of the flat, various objects placed and the different activities done in various zones of that net usable space affects the members of the house.
              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Does an overhead tank has an effect on the residents even though it is not a part of the space occupied by them?</h3>
              <p>
                Yes
              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>If the location of a bedroom, kitchen, toilet or storeroom is not ideal according to Vastu Shastra, is demolition the only option?</h3>
              <p>
                No, even if the bedroom, kitchen toilet, storeroom, temple or any such other structure is not in ideal zone, then there treatment can be done by simple Vastu remedies without any demolition.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Does Vastu Shastra mean demolition and drastic changes in the house / office.</h3>
              <p>
                No applying Vastu Shastra to your home / office does not always mean demolition or drastic changes. Balancing various zones with the help of 5 colour elements, or changing the zones in which some activities are done  or changing the place of some objects Vastu Shastra can be applied to any building. Even if the toilet is in the wrong zone ( except in the North East Zone), it can be treated with the help of very simple yet effective remedies.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Does the direction of stairs i.e. clockwise or anti clockwise also have an effect on the members of the house?</h3>
              <p>
                Yes, the direction of the stairs also impact the members of the house. There are some zones in which the stairs should be clockwise to get its positive results and similarly there are some zones in which the stairs should be anti clockwise the reduce the negative impacts of that zone. The presence of the staircase results in either increasing or supressing the energy of a particular zone depending upon its direction i.e. whether it is clockwise or anticlockwise.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Which is the ideal zone for temple</h3>
              <p>
                The ideal zone for temple/ Puja room is North East or the West Zone. If you cannot find that zone of your house/ office available for temple or puja room, then some alternative zones can also be used for the purpose by applying proper Vastu Shastra.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Which zone is best to keep our bed and does direction in which sleep also impacts our well-being?</h3>
              <p>
                The best zone to place the bed is the South zone or the NNW Zone. While sleeping one must keep their head in either South or East zone.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>If after shifting to a new house, we started facing many health issues, can it be reduced with the help of Vastu remedies</h3>
              <p>
                If you start facing health issues after shifting to a new house, then there is a possibility that some zones of the house are imbalanced. By checking the direction of the entrance and the zone of health and immunity i.e. NNE Zone, one can find out the reason behind health issues and those zones can be treated to achieve desired results i.e. better  health and immunity.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>If the members of the house are not able to clear their higher study exams, can it be due to some Vastu Dosh in the house?</h3>
              <p>
                Yes, imbalanced zones of the house can result in causing hindrances in the higher studies of the members of the house. If the North zone or the WSW zone of the house is disturbed, it causes disturbance in higher studies and career opportunities.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>If the bonding between family members is getting weaker day by day and many heated arguments are taking place, can Vastu Dosh be the reason for such unhealthy relationships?</h3>
              <p>
                Yes, according to Vastu Shastra, there is a zone of family bonding and relationship and if that zone is disturbed / imbalanced it can result in heated arguments amongst the members of the family. If in the zone of family bonding there is gas stove or toilet, it spoils the bonding between the members.              </p>
            </div>
          </div>

          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>If some members of the house feel depressed and have lost zeal in life can Vastu shastra help to regain their enthusiasm and love for life</h3>
              <p>
                Yes, if the WNW or ENE zone of the house is disturbed or if the toilet is located in the ENE  zone, it can cause depression or loss of zeal in the members of the house. Any member whose bedroom is in the WNW or ENE zone can also undergo depression.              </p>
            </div>
          </div>


          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>If any person doesn’t know their birth details i.e. date, time and place of birth, can their queries be solved with the help of Astrology</h3>
              <p>
                Yes, with the help of  Personal Kundli any questioned asked by a person can be answered even if their birth details are not known.              </p>
            </div>
          </div>

          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Does the buildings surroundings a house/ office also have an effect on the residents. For eg. If there is a highway, open field, crematory, school or hospital surrounding any building, will it effect the residents according to Vastu Shastra?</h3>
              <p>
                Yes, the structures surrounding a building have a long term impact on the residents. Keeping in mind the surroundings of a house/office, Vastu Shastra remedies are applied to get the desired results.              </p>
            </div>
          </div>

          <div className='Faq-Ques-Ans-Con'>
            <div className='Faqs-QUes-Logo-con'>
              <div className='FAQ-Q-Logo'>
                <BsPatchQuestion />
              </div>
            </div>
            <div className='Faq-Ques-Ans'>
              <h3>Does T-Hit junction impacts the Vastu of the house.</h3>
              <p>
                Yes, T-Hit junction can have different impacts on the Vastu of the house depending upon the zone it is hitting. And after considering the zone hit bt T junction Vastu remedies are applied.              </p>
            </div>
          </div>

        </div>

      </section>
      <Footer />
    </>
  )
}

export default FAQS
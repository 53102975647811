import React, { useLayoutEffect } from 'react';
import "../CSS/Home.css";
import Misiion from "../Images/Residential Vastu.jpg";
import Footer from "../Component/Footer";
// // ----------------- Services Logo ------------------------

import Hotel from "../Images/Logo Service/Hotel Vastu.png";
import plots from "../Images/Logo Service/Plot_service_resi.png";
import Flats from "../Images/Logo Service/Flat Selection.png";
import House from "../Images/Logo Service/House_resi.png";
import Correction from "../Images/Logo Service/Resi_correction.png";
import Office from "../Images/Logo Service/Vastu For Office.png";
import Shops from "../Images/Logo Service/Shop Vastu.png";
import Hospital from "../Images/Logo Service/Hospital Vastu.png";
import Mall from "../Images/Logo Service/Mall Vastu.png";
import industry from "../Images/Logo Service/industry.png"
import ShowImage01 from "../Images/BackImages/Show-Iamge.png";

import AstroVastuRemedies from "../Images/Logo Service/Astro Vastu Remedies.png";




// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules

import { Autoplay, Pagination, Navigation } from "swiper";


// Testimonial 

import KomalSinghal from "../Images/Reviews/Komal Singhal.jpeg";
import ManaliShah from "../Images/Reviews/Manali Shah 01.jpeg";
import ReshuMehta from "../Images/Reviews/Reshu Mehta 01.jpeg";
import SagarShah from "../Images/Reviews/Sagar Shah 01.jpeg";
import RinkuAgarwal from "../Images/Reviews/Rinku Agarwal 01.jpeg";
import SwatiAmbani from "../Images/Reviews/Swati Ambani 01.jpeg";
import NehaandPravin from "../Images/Reviews/Neha and Pravin.jpeg";
import ViralShah from "../Images/Reviews/Viral Shah.jpeg";
import Puravshah from "../Images/Reviews/Purav shah.jpeg";
import DeepakArora from "../Images/Reviews/Deepak Arora.jpeg";
import NupurParikh from "../Images/Reviews/Nupur Parikh.webp";

/////////

import Mahip from "../Images/Mahip.jpg";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            {/* /////////////////////// SEO ////////////////////////// */}

            <Helmet>
                <meta name='description' content='Mahip Agarwal is one of the best vastu expert in Mumbai. With more the 10 years as vastu consultant he helped lot of his clients achieve success. Call Now!' />
                <meta name="title" content="Best Vastu Expert in Mumbai | By Mahip Agarwal  Consult Now!" />
                <meta name="keywords" content="best vastu consultant,vastu consultant,vastu shastra consultant,vastu expert in mumbai,vastu consultant in mumbai,best vastu consultant in mumbai,best vastu expert in Mumbai" />
                {/* <!-- Open Graph --> */}
                <meta property="og:url" content="http://divineastrovastu.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Divine Astro Vastu" />

                <link rel="canonical" href="http://divineastrovastu.in/" />
            </Helmet>

            {/* /////////////////////// SEO ////////////////////////// */}


            {/* //////////////////////// Home-Section 3 ////////////////////////////  */}


            <section className="Home-section-3">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 order-sm-1 order-2">
                            <div className="Home-AboutUs">
                                <div className="Home-About-Text">
                                    about us
                                </div>
                                <div className="Home-About-Info">
                                    <h1>Divine Astro Vastu</h1>
                                </div>
                                <div className="home-AboutUs-Para">
                                    <p>Vastu can be applied to any structure/ building however small or big in size it may be and effective results can be obtained in short span of time. The remedies provided by us involve minimum demolition and astonishing results can be obtained by just few changes. Whether you own a Renkadi or a factory spread in hundreds of acres, Vastu remedies can be applied to any structure to help the business get maximum results with minimum efforts.</p>
                                    <p>Vastu helps a business to attract new clients, to retain their old clients, to develop their goodwill, to avoid wastage of materials and efforts, to have cooperative staff, to get financial support and in many such other ways which can help to bring growth and prosperity for any business.</p>
                                </div>
                                <hr></hr>
                                <div className="HS-3-Sin">
                                    <div className="Digital-Sin-name">
                                        <div className="Degital-name">
                                            <h4>Mahip Agarwal</h4>
                                            <h5>Maha Vastu Expert and Astrologer</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 order-sm-2 order-1">
                            <div className="Home-About-Image-con" title='Best Astrologer In Mumbai'>
                                <div className="Home-About-Image">
                                    <img src={Mahip} alt="Mahip Agarwal" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////// Section 4 ////////////////////////////// */}

            <section className='Home-Section-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='Mission-Section-Image-con'>
                                <div className='Mission-Section-Image'>
                                    <img src={Misiion} alt="mission" />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='Mission-Information-con'>
                                <div className='Mission-Information'>
                                    <h3>
                                        Our Mission
                                    </h3>
                                    <p>
                                        The mission of Divine Astro Vastu is to create a prosperous life for our clients by helping them to have more Joy, Love, Growth, Peace of mind and Happiness in their lives. Vastu Expert Mahip Agarwal has provided almost 100% results to his clients in their personal as well as professional lives. Vastu Shastra combined with Astrology provides complete solution to various issues faced by people around the world. The remedies suggested by us are easy to implement involving minimum demolition and providing maximum results leading to high satisfaction level of our clients. After following the Astro suggestions or applying the Vastu remedies people have seen instant results which has increased their confidence and trust in our remedies as well as our work.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ------------------------------- Section 5 ------------------------------ */}

            <section className="HomeSection5">
                <div className="ast_service_wrapper ast_toppadder70 ast_bottompadder50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="HomeS-Services">
                                    <div className="HomeS-Services-Text">
                                        <h2>Our Services</h2>
                                        <div className="Home-Show-Image1-con">
                                            <div className="HomeShowImage">
                                                <img src={ShowImage01} alt="" />
                                            </div>
                                        </div>
                                        <div className="HomeS-Services-para">
                                            <p>Vastu remedies can be applied to any structure to help the business get maximum results with minimum efforts. Vastu helps a business to attract new clients, to retain their old clients, to develop their goodwill, to avoid wastage of materials and efforts, to have cooperative staff, to get financial support and in many such other ways which can help to bring growth and prosperity for any business.</p>
                                        </div>
                                        <div className='Service-btn'>
                                            <button>
                                                <Link to="/Services">
                                                    Show More
                                                </Link>
                                            </button>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={House} alt="House Selection vastu" />
                                    <h4>House Selection</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={Office} alt="Vastu For Office" />
                                    <h4>Vastu For Office</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={Shops} alt="Vastu for Shops and Showroom" />
                                    <h4>Vastu for Shops and Showroom</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={Hotel} alt="Vastu for Hotel and Restaurants" className="ServicesLog" />
                                    <h4>Vastu for Hotel and Restaurants</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={Hospital} alt="Vastu for Hospitals and Clinics" />
                                    <h4>Vastu for Hospitals and Clinics</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={Mall} alt="Vastu for mall" />
                                    <h4>Vastu for mall</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={industry} alt="Vastu for Industries and Factory" className='LogoImage' />
                                    <h4>Vastu for Industries and Factory </h4>
                                    <div className="clearfix" />
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={Flats} alt="Home, flat or office selection vatu" />
                                    <h4>Home, flat or office selection</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={plots} alt="Site or plot selection vastu" />
                                    <h4>Site or plot selection</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={Correction} alt="Planning of New house according to Vastu " />
                                    <h4>Planning of New house according to Vastu </h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={AstroVastuRemedies} alt="Astro Vastu remedies" className='LogoImage' />
                                    <h4>Astro Vastu remedies</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="ast_service_box">
                                    <img src={AstroVastuRemedies} alt="Astrology Vastu" className='LogoImage' />
                                    <h4>Astrology</h4>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* /////////////////////////// Section 5 ////////////////////////////// */}

            <section className="Home-Section-5">
                <div className='Home-Section-Details'>
                    <h2>
                        why choose us
                    </h2>
                </div>
                <div className='container'>
                    <div className='Why-Us-Section'>
                        <h3>
                            Divine Astro Vastu:
                        </h3>
                        <p>
                            1. The remedies suggested by us involves minimum demolition. The remedies are very easy to implement and does not involve drastic changes.
                        </p>
                        <p>
                            2.	Almost any kind of problem faced by an individual in their personal or professional life can be solved by applying Vastu remedies and Astro suggestions.
                        </p>
                        <p>
                            3.	Scientific techniques are used by us which program the surroundings in such a way that favourable circumstances are created around the individual which helps them to manifest their desires
                        </p>
                        <p>
                            4.	The remedies suggested by us are tried and tested Vastu techniques which helps to create an excellent life for our clients.
                        </p>
                        <p>
                            5.	The Vastu remedies provided by us helps to create a balanced environment and hence the individuals get better results for their inputs and efforts.
                        </p>
                    </div>
                </div>
            </section>


            {/* ------------------------------- Section 6 ------------------------------ */}

            <section className="HomeSection6">
                <div className="container">
                    <div className="HomeS_Testimonial-con">

                        <div className="HomeS-WhyUS">
                            <div className="HomeS-WhyUs-Text">
                                <h2 style={{ color: "white" }}>What Our Customers Say</h2>
                                <div className="Home-Show-Image1-con">
                                    <div className="HomeShowImage">
                                        <img src={ShowImage01} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Testimonial-main-con">
                            <div className="Testimonial-con">
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >



                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={ReshuMehta} alt="Reshu Mehta" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Reshu Mehta
                                                </h2>
                                                <p>
                                                    I am very much impressed with his deep and thorough knowledge in Astrology and vastu. He is very expert in his subject matter and very patient listener. He is able to convey past ,present and future with accuracy. It is amazing. Moreover he suggests remedies which are very simple and very effective. I personally experienced the impact of his remedies in my life as well as my family's life and got benefitted with those. I strongly recommend him and wish him all success in his life and moreover I appreciate his noble approach to help people.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={ManaliShah} alt="Manali Shah" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Manali Shah
                                                </h2>
                                                <p>
                                                    Hellooo everyone his vastu is very accurate in my case it’s 10 /10 n his way of telling to people is very good n in positive way he greets people coming to vastu n kundli it’s very effective n perfect
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={SagarShah} alt="Sagar Shah" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Sagar Shah
                                                </h2>
                                                <p>
                                                    Thank you Mahip Agarwalji for helping me grow my business with the best vastu tips you gave me. Your terrific and in-dept knowledge came in as a blessings. Your calculations are impeccable. Your easy to do remedies helped us to decode the exact root of the problem. As a result you have the ability to suggest solutions with pin pointed accuracy.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={RinkuAgarwal} alt="Rinku Agarwal" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Rinku Agarwal
                                                </h2>
                                                <p>
                                                    I had health concerns for 10 years, and the rest other things were very good in life. Went to almost 20+ doctors across the country and no one was able to detect the reason. The pain and issues used to come only during sleeping hours and for years I didn’t sleep properly, Mahip Agarwal helped us by making few changes with no major expenses which made my health issues go and I could sleep properly. He is amazing, doesn’t scare you but shows simple solutions which are very effective”
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={SwatiAmbani} alt="Swati Ambani" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Swati Ambani
                                                </h2>
                                                <p>
                                                    Thank you Mahip bhai for demonstrating a high a degree of knowledge and analysing the situations and further forecasting the effects in my career.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={NehaandPravin} alt="Neha and Pravin" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Neha and Pravin
                                                </h2>
                                                <p>
                                                    One can never know how Vastu can impact their life untill you consult a vastu expert.. This is one of the initmate detail to share with anyone and so we needed someone we could trust on.. And thats where exactly we met Mahip and there cant be anyone else we could trust this much. I think so trust is most important thing when you want any change in your life. Mahip is patient and thorough with his process. He is calm and good listener. He understands the problems with depth and studies it and has most suitable solutions for the issue. He looks at the issues from multiple angles, considers unconventional inputs and leave no stone unturned to solve them. We thank him for his selfless, friendly and accommodative approach to our issues. We wish him best of success and bringing smiles in countless families he would be consulting in future.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={NupurParikh} alt="Nupur Parikh" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Nupur Parikh
                                                </h2>
                                                <p>
                                                    It has been an absolute delight consulting Mahip. He is a thorough professional and an expert in Vastu. More so, he understands the pulse of any issue, making it easy for us to be straightforward with him. We loved the fact that Mahip's remedies are easy, light on pocket and effective. He goes out of his way to help his clients, which is a rare quality found in Vastu consultants. We are very happy with his service and will consult again.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={ViralShah} alt="Viral Shah" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Viral Shah
                                                </h2>
                                                <p>
                                                    I have been consulting Mahip Bhai since last many years regarding any professional or personal decisions to be taken and he has guided me to take the best decisions. I have seen immediate results after applying the remedies suggested by him. The remedies suggested by him are very easy to implement and very effective. I trust him a lot and before taking any important decision of my life, I consult him first. I highly recommend him as one of the best Astrologer and Vastu Consultant.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={Puravshah} alt="Purav shah" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Purav shah
                                                </h2>
                                                <p>
                                                    I am really happy with the results and with the way he explains and his remedies are of minimal cost. I would recommend him 100% for any kind of issues in life. 😊
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={DeepakArora} alt="Deepak Arora" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Deepak Arora
                                                </h2>
                                                <p>
                                                    This may sound too much but trust us what happened, we have been asking Mahip things about our lives for a long but greatest gift was the birth timing planning of our son who was borne through C-section. He is borne such a winner attitude and amazing mind that he is almost 5 years ahead of his age with many records, we never thought that this could also happen, but happened with us
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide>
                                        <div className="Testimonial-Swper">
                                            <div className="testimonialDetails">
                                                <div className='Profile-Logo-con'>
                                                    <div className='Profile-Logo'>
                                                        <img src={KomalSinghal} alt="Komal Singhal" />
                                                    </div>
                                                </div>
                                                <h2 className="testimonialh2">
                                                    Komal Singhal
                                                </h2>
                                                <p>
                                                    The man in general public just like any of us but he has been studying this subject of astrology since years.. he is an expert in field of vastu and astrology... If you think you need some small solutions to your problems then he is your person...if you need some big changes in your life ...he can guide you vastu wise as well as astrology wise...he is very knowledgeable in field of speaking nature of human through kundali.. you get to know even know your own self through kundali and make changes in whatever way is required as per your suitability and circumstance....do try him for amazing results
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />
        </>
    )
}

export default Home
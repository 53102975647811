import React from 'react';
import { Link } from "react-router-dom";
import "../CSS/Footer.css"
// import FooterLogo from "../Images/WHATEVER EVENTS LOGO.png";

import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { RiWhatsappLine, RiFacebookFill, RiInstagramFill } from 'react-icons/ri';
import Logo from "../Images/DAV-Logo.png";

const Footer = () => {
    return (
        <>
            <section className='Footer-Section'>
                <div className='container'>
                    <div className='Footer-con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='Footer-Logo-con'>
                                    <div className='Footer-Image-Con'>
                                        <div className='Footer-Image'>
                                            <img src={Logo} alt="" />
                                        </div>
                                    </div>
                                    <p>
                                        We aim to bring health, prosperity, growth, peace of mind, fun and happiness in people's lives. Our Vastu techniques balance various zones of the property in such a way that positive energy is created  which helps people to connect to the right people, take right decisions and move in the right direction.
                                    </p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='footer-Mid-Col'>
                                    <div className='nav_menu-2'>
                                        <h3 className='footer-title'>
                                            Explore
                                        </h3>
                                        <div className='menu-footer-menu-container'>
                                            <ul className='menu-footer-menu'>
                                                <li className='menu-item'>
                                                    <Link to='/'>
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/vastu-consultant'>
                                                        Service
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/vastu-shastra-consultant'>
                                                        Testimonial
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/vastu-expert-in-mumbai'>
                                                        Faqs
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/best-vastu-consultant-in-mumbai'>
                                                        Contact
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='footer-column'>
                                    <div className='widget'>
                                        <h3>Get in touch</h3>
                                        <div className='footer-contact'>
                                            <p className='footer-contact-text'>
                                                Flat no 10, 3rd floor, A wing
                                                Damodar bhavan,
                                                Vallabhbhai patel Road,
                                                Vile Parle West
                                                Mumbai - 400056
                                                Landmark: Shubham hall
                                            </p>
                                            <div className='footer-contact-info'>
                                                <p className='footer-contact-phone'>
                                                    <span className='ti-headphone-alt'>
                                                        <TfiHeadphoneAlt />
                                                    </span>
                                                    <a>+91 90049 22225</a>
                                                </p>
                                                <p className='footer-contact-mail'>
                                                    <a>
                                                        astrovastumahip@gmail.com
                                                    </a>
                                                </p>
                                            </div>
                                            <div className='Footer-Social-Media'>
                                                <a href='https://wa.me/9004922225?text=' target='black'>
                                                    <RiWhatsappLine />
                                                </a>
                                                <a href='https://www.facebook.com/profile.php?id=100085238311439' target='black'>
                                                    <RiFacebookFill />
                                                </a>
                                                <a href='https://www.instagram.com/divineastrovastu/' target='black'>
                                                    <RiInstagramFill />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Copy-Right'>
                        <div className='Contact-Us-Copy'>
                            © Copyright
                            <strong>
                                <span style={{ color: "#d6a063" }}>DIVINE ASTRA VASTU</span>
                            </strong>
                            . All Rights Reserved
                        </div>
                        <div className="credits">
                            Designed by
                            <a href="https://skdm.in/" target='black' style={{ color: "#d6a063" }}>
                                Shree Krishna Digital
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer
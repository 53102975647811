import React from 'react';
import "../CSS/NavBar.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import Logo from "../Images/DAV-Logo.png";

const NavBar = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <div className='mid-logo'>
                                <div className='MainLogoImage'>
                                    <img src={Logo} alt="Logo" />
                                </div>
                            </div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link to='/'>Home</Link>
                            <Link to='/best-vastu-consultant'>Benefits of vastu</Link>
                            <Link to='/vastu-consultant'>Services</Link>
                            <Link to='/vastu-shastra-consultant'>Testimonial</Link>
                            <Link to='/vastu-expert-in-mumbai'>Faq's</Link>
                            <Link to='/vastu-consultant-in-mumbai'>Myths</Link>
                            <Link to='/best-vastu-consultant-in-mumbai'>Contact</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar
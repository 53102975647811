import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import "../CSS/Service.css";
import ResidentialVastu from "../Images/Services Logo/Residential Vastu.jpg";
import VastuforOffices from "../Images/Services Logo/vastu_for_office.jpg";
import VastuforShopsorShowrooms from "../Images/Services Logo/Shop Vastu.jpg";
import VastuForfactory from "../Images/Services Logo/vastu for factory.jpg";
import VastuforHospitals from "../Images/Services Logo/vastu_for_hospital-4.jpg";
import VastuForPlots from "../Images/Services Logo/Vastu Plots.jpg";
import HouseVastu from "../Images/Services Logo/hands-framing-kitchen-layout-2004.jpg";
import planningfornewhome from "../Images/Services Logo/vastu_for_flats.jpg";
import Astrology from "../Images/Services Logo/Astrology.jpeg";
import AstroVastuRemedies from "../Images/Services Logo/Astro Vastu Remedies.jpg";
import Footer from "../Component/Footer";



const Services = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>
      {/* /////////////////////// SEO ////////////////////////// */}

      <Helmet>
        <meta name='description' content='Mahip Agarwal is one of the best vastu expert in Mumbai. With more the 10 years as vastu consultant he helped lot of his clients achieve success. Call Now!' />
        <meta name="title" content="Best Vastu Expert in Mumbai | By Mahip Agarwal  Consult Now!" />
        <meta name="keywords" content="best vastu consultant,vastu consultant,vastu shastra consultant,vastu expert in mumbai,vastu consultant in mumbai,best vastu consultant in mumbai,best vastu expert in Mumbai" />
        {/* <!-- Open Graph --> */}
        <meta property="og:url" content="http://divineastrovastu.in/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Divine Astro Vastu" />

        <link rel="canonical" href="http://divineastrovastu.in/ " />
      </Helmet>

      {/* /////////////////////// SEO ////////////////////////// */}


      <section className='Service-Section-1'>
        <div className='Service-Text'>
          <h1>Services provided by us </h1>
        </div>
        <div className='container'>
          <div className='Service-s-main-con'>


            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>

                  <div className='col-md-4'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image'>
                        <img src={ResidentialVastu} alt="Residential Vastu" />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    <div className='Service-details-con'>
                      <div className='Service-details'>
                        <h3>Residential Vastu</h3>
                        <p>When a new house is constructed according to Vastu or when Vastu remedies are applied to a house already built, it can change the vibes and flow of energy in the house. When people enter a  Vastu compliant house, they can feel some positive vibes which makes them happy , joyful, motivated , calm and comfortable. The people living in a Vastu compliant house show positive attitude, have good health and experience more fun and enjoyment in their lives. On the other hand, people living in a house not built according to Vastu may feel negative, anxious and face various issues like poor health conditions, lack of new opportunities, heated arguments, aggressiveness and many more such related problems.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>
                  <div className='col-md-8 order-md-1 order-2'>
                    <div className='Service-details-con'>
                      <div className='Service-details Service-details-Sec'>
                        <h3>Vastu for Offices</h3>
                        <p>When proper Vastu Shastra is applied to the offices according to work profile of the clients, it can help them to acquire supportive staff, to gain name and fame, to attract new opportunities and in the overall growth of their business/ profession. When the office of the client is Vastu compliant, they can avoid lack of vision, taking wrong decisions, blocking of payments, commitment failures and many such other problems can be avoided with the help of simple Vastu remedies.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 order-md-2 order-1'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image Service-Image-sec'>
                        <img src={VastuforOffices} alt="Vastu for Offices" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>

                  <div className='col-md-4'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image'>
                        <img src={VastuforShopsorShowrooms} alt="Vastu for Shops or Showrooms" />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    <div className='Service-details-con'>
                      <div className='Service-details'>
                        <h3>Vastu for Shops or Showrooms</h3>
                        <p>The shops or showrooms designed according to Vastu can attract huge number of customers. When the entrance of the shops or offices and arrangement of furniture and fixtures is done according to Vastu, it can create such energy that the customers subconscious mind is programmed to buy their products. The Vastu compliant shops and showrooms can see more footfall, new opportunities , fast recovery of payments, high reputation and more growth opportunities.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>





            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>
                  <div className='col-md-8 order-md-1 order-2'>
                    <div className='Service-details-con'>
                      <div className='Service-details Service-details-Sec'>
                        <h3>Vastu for Industries and Factory</h3>
                        <p>When Vastu for Industries is done based on understanding the whole production process , the main priorities of the product and the targeted results of the clients , it can help them to achieve long term success. The Vastu compliant Industries can achieve great success, improve their relations with clients as well as vendors, meet their order commitments , have strong financial and banking support, can have clear vision for expansion and growth and many such other benefits leading to long lasting growth and success of their business.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 order-md-2 order-1'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image Service-Image-sec'>
                        <img src={VastuForfactory} alt="Vastu for Industries and Factory" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>

                  <div className='col-md-4'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image'>
                        <img src={VastuforHospitals} alt="Vastu for Hospitals" />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    <div className='Service-details-con'>
                      <div className='Service-details'>
                        <h3>Vastu for Hospitals</h3>
                        <p>Hospitals when built according to Vastu Shastra in which there is ideal arrangement of different activities and objects can help in attracting better health and fastening the healing process. Vastu remedies can be applied to hospitals in such a way that healing, recovery, good health and fitness can become a natural attribute of such building.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>







            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>
                  <div className='col-md-8 order-md-1 order-2'>
                    <div className='Service-details-con'>
                      <div className='Service-details Service-details-Sec'>
                        <h3>Site Selection</h3>
                        <p>Vastu Shastra can help a client to choose a perfect site for his business. Irrespective of the shape of the plot, quality of soil or the slope of land, the plot selected according to Vastu can bring immense growth and prosperity in the business intended to be started on that plot. The plots selected based on the effective Vastu techniques can help a business to gain fame and reputation, attract new opportunities, get financial and banking support, get a cooperative team with a clear vision and experience growth and expansion.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 order-md-2 order-1'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image Service-Image-sec'>
                        <img src={VastuForPlots} alt="Site Selection" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>






            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>

                  <div className='col-md-4'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image'>
                        <img src={HouseVastu} alt="Selection of a new House" />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    <div className='Service-details-con'>
                      <div className='Service-details'>
                        <h3>Selection of a new House</h3>
                        <p>If you are planning to rent a new house or buy it, we can help you to choose the right house which would help in overall growth and well being of the family members. Based on the Kundli and Vastu techniques we check whether the location of bedroom is correct or not, entrance of the house is suitable for the client or not and help them to avoid such houses which can cause poor health, loss of fame and monetary losses.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>





            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>
                  <div className='col-md-8 order-md-1 order-2'>
                    <div className='Service-details-con'>
                      <div className='Service-details Service-details-Sec'>
                        <h3>Guidelines in planning for new home according to Vastu</h3>
                        <p>If you are planning to build a new house or renovate your old house, we can guide you with the right direction for the location of the bedrooms, kitchen and toilets. With the help of Vastu techniques we can help you to build your entrance in such a direction which can help to attract good health, flow of money and social connections. The house constructed according to Vastu helps to create positive vibes and the members of the house feel more energetic and motivated to do their work. The members of a Vastu compliant house feel more confident, have a clear vision and are able to build strong social connections which helps in their growth in personal as well as professional life.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 order-md-2 order-1'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image Service-Image-sec'>
                        <img src={planningfornewhome} alt="Guidelines in planning for new home according to Vastu" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>






            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>

                  <div className='col-md-4'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image'>
                        <img src={Astrology} alt="Astrology" />
                      </div>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    <div className='Service-details-con'>
                      <div className='Service-details'>
                        <h3>Astrology</h3>
                        <p>The Astrology services provided by us is like a guiding light helping you to move in the right direction. With the help of our services, you can take important decisions of your life with much more confidence and see drastic changes in your personal as well as professional life. The birth Chart can tell so many things about a person which even he/she may not be aware of. Astrology services provided by us can help a person to decide the right business or profession for him/ her to achieve maximum success. With the help of birth chart, we can help you to choose right partner for life by matching kundlis.. With the help of Kundli we can guide you about any health issues you may face in the future and help you to take precautions to minimise its effects. We also make personal Kundli to answer any query which may be bothering you.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='Service-Container'>
              <div className='Service-Swiper-con'>
                <div className='row'>
                  <div className='col-md-8 order-md-1 order-2'>
                    <div className='Service-details-con'>
                      <div className='Service-details Service-details-Sec'>
                        <h3>Astro Vastu Remedies</h3>
                        <p>Vastu Shastra combined with Astrology provides complete solution to various issues faced by people around the world.  Vastu combined with astrology can provide astonishing results to the client. When a house is Vastu compliant and Astro remedies are provided based on the birth chart of the client, maximum benefits can be obtained with minimum efforts. Astro Vastu remedies can help people to get particular results and help them to manifest their desires.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 order-md-2 order-1'>
                    <div className='Service-Image-con'>
                      <div className='Service-Image Service-Image-sec'>
                        <img src={AstroVastuRemedies} alt="Astro Vastu Remedies" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </section>

    </>
  )
}

export default Services